import endPoints from "../EndPoint"
import { deleteToken } from "../requests/deleteToken"
import { getToken } from "../requests/getToken"
import { postToFormDataToken } from "../requests/postToFormDataToken"
import { putToFormDataToken } from "../requests/putToFormDataToken"
import { returnActiveParasutAccount } from "@/helpers/ReturnActiveParasutAccount"

// Şirkete göre tüm ürünleri listeleme
const getProducts = (productCategoryId, page, limit, search, productType) => {
    return getToken(endPoints.productEndPoints.GET_PRODUCTS_URL(productCategoryId, page, limit, search, productType))
}

// ürün ekleme
const addProduct = (form) => {
    let data = new FormData();
    data.append('name', form.name);
    data.append('isPriority', form.isPriority);
    data.append('description', form.description);
    data.append('productCategoryId', form.productCategoryId.id);
    data.append('price', form.price);
    data.append('vatRate', form.vatRate);
    data.append('stockCount', form.stockCount);
    data.append('applyDiscount', form.applyDiscount);
    data.append('parasutAccountId', returnActiveParasutAccount());
    data.append('image', form.image);
    if (form?.productInfos?.length > 0) {
        form.productInfos.map((item) => {
            data.append('productInfos', JSON.stringify({
                productId: item.productId,
                isExtraProduct: item.isExtraProduct,
                quantity: item.quantity
            }));
        })
    }

    return postToFormDataToken(endPoints.productEndPoints.ADD_PRODUCT_URL, data)
}

// Ürün silme
const deleteProduct = (productId) => {
    return deleteToken(endPoints.productEndPoints.DELETE_PRODUCT_URL(productId))
}

// Ürün detay getirme
const getProductDetail = (productId) => {
    return getToken(endPoints.productEndPoints.GET_PRODUCT_DETAIL_URL(productId))
}

// Ürün aktiflik değiştirme
const updateProductActive = (productId, activeType) => {
    let data = new FormData();
    data.append('isActive', activeType)

    return putToFormDataToken(endPoints.productEndPoints.UPDATE_PRODUCT_ACTIVE_URL(productId), data)
}


// Ürün öncelik değiştirme
const updateProductPriorty = (productId, priorityType) => {
    let data = new FormData();
    data.append('isPriority', priorityType)

    return putToFormDataToken(endPoints.productEndPoints.UPDATE_PRODUCT_PRIORITY_URL(productId), data)
}

// Paket ve ürün güncelleme
const updateProductPackages = (packageId, form) => {
    let data = new FormData();
    data.append('name', form.name);
    data.append('isPriority', form.isPriority);
    data.append('description', form.description);
    data.append('productCategoryId', form.productCategoryId.id);
    data.append('price', form.price);
    data.append('vatRate', form.vatRate);
    data.append('stockCount', form.stockCount);
    data.append('parasutAccountId', returnActiveParasutAccount());
    data.append('applyDiscount', form.applyDiscount);
    data.append('image', form.image);
    if (form?.productInfos?.length > 0) {
        form.productInfos.map((item) => {
            data.append('productInfos', JSON.stringify({
                productId: item.productId,
                isExtraProduct: item.isExtraProduct,
                quantity: item.quantity
            }))
        })
    }

    return putToFormDataToken(endPoints.productEndPoints.UPDATE_PRODUCT_PACKAGES_URL(packageId), data)
}

export const productApi = {
    getProducts,
    addProduct,
    deleteProduct,
    getProductDetail,
    updateProductActive,
    updateProductPriorty,
    updateProductPackages
}