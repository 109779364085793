import endPoints from "../EndPoint"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"

// Çalışana ait satışlar listesi
const getEmployeeSaleList = (employeeId, page, limit, saleType) => {
    return getToken(endPoints.saleEndPoints.GET_EMPLOYEE_SALE_LIST(employeeId, page, limit, saleType))
}

// Müşteriye ait satışlar listesi
const getCustomerSaleList = (customerId, page, limit, saleType) => {
    return getToken(endPoints.saleEndPoints.GET_CUSTOMER_SALE_LIST(customerId, page, limit, saleType))
}

// Plana ait satışlar listesi
const getPlanSaleList = (planId, page, limit, saleType) => {
    return getToken(endPoints.saleEndPoints.GET_PLAN_SALE_LIST_URL(planId, page, limit, saleType))
}

// Tüm satışların listesi
const getSaleList = (page, limit, saleType, search, employeeId, paymentType, payType, timeType, filterDate) => {
    return getToken(endPoints.saleEndPoints.GET_SALE_LIST_URL(page, limit, saleType, search, employeeId, paymentType, payType, timeType, filterDate))
}

// Satışın detay bilgilerini getirir.
const getSaleDetail = (saleId) => {
    return getToken(endPoints.saleEndPoints.GET_SALE_DETAIL_URL(saleId))
}

// Tüm satışların listesi
const changeSale = (saleId, saleType, saleDate) => {
    let data = {
        "id": saleId,
        "saleType": saleType,
        "saleDate": saleDate
    }
    return postToken(endPoints.saleEndPoints.CHANGE_SALE_URL, data)
}

export const saleApi = {
    getEmployeeSaleList,
    getCustomerSaleList,
    getPlanSaleList,
    getSaleList,
    changeSale,
    getSaleDetail
}