import api from "@/services";

export const bankAccounts = {
  state: {
    status: "uninitialized",
    bankAccounts: [],
  },
  getters: {
    getBankAccountsListStatus(state) {
      return state.status;
    },
    getBankAccounts: (state) => {
      return state.bankAccounts;
    },
  },
  mutations: {
    SET_BANK_ACCOUNTS_LOADING(state, payload) {
      state.status = payload || "uninitialized";
    },
    SET_BANK_ACCOUNTS(state, payload) {
      state.bankAccounts = payload;
    },
  },
  actions: {
    async GET_ALL_BANK_ACCOUNTS_LIST({ commit, state }) {
      if (state.status === "success" && state.bankAccounts.length > 0)
        return state.bankAccounts;
      commit("SET_BANK_ACCOUNTS_LOADING", "loading");
      console.log(state, "bankAccounts");
      const response = await api.bankAccountApi.getBankAccountsList();
      if (response.message === "OK") {
        console.log(response.data);
        commit("SET_BANK_ACCOUNTS", response.data.items);
        commit("SET_BANK_ACCOUNTS_LOADING", "success");
        return response.data;
      } else commit("SET_BANK_ACCOUNTS", []);
      commit("SET_BANK_ACCOUNTS_LOADING", "success");
      return [];
    },
    SET_BANK_ACCOUNTS_ACTION({ commit }, payload) {
      commit("SET_BANK_ACCOUNTS", payload);
    },
  },
  modules: {},
};
