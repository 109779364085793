import endPoints from "../EndPoint"
import { getToken } from "../requests/getToken"

// Paraşüt hesap ve marka bilgilerini getirir.
const getParasutAccountList = () => {
    return getToken(endPoints.parasutAccountEndPoints.GET_PARASUT_ACCOUNT_LIST)
}

export const parasutAccountApi = {
    getParasutAccountList
}