import Vue from 'vue'
import App from './App.vue'
import router from './router/Router'
import '../src/assets/scss/main.scss'
import { BootstrapVue } from 'bootstrap-vue'
import "../src/assets/scss/Cards.scss";
import '../src/assets/css/style.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import i18n from './i18n'
import VCalendar from 'v-calendar';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import store from './store/index'
import Vuelidate from 'vuelidate'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
// Use v-calendar & v-date-picker components
Vue.use(VCalendar);
// Form Validate
Vue.use(Vuelidate)
Vue.component("v-select", vSelect);

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
