// export const baseUrl = 'https://api-slimwell.eralpsoftware.net'
console.log(location.origin);
const demoBaseUrl = "https://api-ssp.devheoxa.com";
const slimwellBaseUrl = "https://slimwell-api.softwaremaklerpro.de";
let url = "";
if (
  location.origin.includes("demo-ssp") ||
  location.origin.includes("192.168.1.")
)
  url = demoBaseUrl;
else if (
  location.origin.includes("slimwell") ||
  location.origin.includes("localhost")
)
  url = slimwellBaseUrl;

export const baseUrl = url;
// export const baseUrl = "http://192.168.1.98:9090";
