import endPoints from "../EndPoint";
import { filePostToken } from "../requests/filePostToken";
// import { getNoPdfToken } from "../requests/getNoPdfToken"
import { getPdfToken } from "../requests/getPdfToken";

// Alınan resmin formatını değiştirme
const fileRoute = (value) => {
  let data = {
    filePath: value,
  };
  return filePostToken(endPoints.fileEndPoints.GET_FILE_ROUTE_URL, data)
};

//  Yapılan satışın makbuzunu gösterir
const getSaleReceipt = (saleId) => {
  return getPdfToken(endPoints.fileEndPoints.GET_SALE_RECEIPT_URL(saleId));
};

export const fileApi = {
  fileRoute,
  getSaleReceipt,
};
