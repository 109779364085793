import endPoints from "../EndPoint"
import { deleteToken } from "../requests/deleteToken"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"
import { putToken } from "../requests/putToken"

// Çalışanın müşteriye atmış olduğu notlar
const getEmployeeNoteList = (customerId, page, limit, planId, date, employeeId) => {
    return getToken(endPoints.noteEndPoints.GET_EMPLOYEE_NOTE_LIST_URL(customerId, page, limit, planId, date, employeeId))
}

// Adminin müşteriye atmış olduğu notlar
const getAdminNoteList = (customerId, adminId, page, limit, planId, employeeId) => {
    return getToken(endPoints.noteEndPoints.GET_ADMIN_NOTE_LIST_URL(customerId, adminId, page, limit, planId, employeeId))
}

// Notları favoriye ekleme
const updateFavoriteNote = (customerId, isFavourite) => {
    let data = {
        'isFavourite': !isFavourite,
    }

    return putToken(endPoints.noteEndPoints.UPDATE_FAVORITE_NOTE_URL(customerId), data)
}

// Not ekleme
const addNote = (form, planId, userId, customerId) => {
    let data = {
        plainText: form.plainText,
        planId: planId,
        isAdmin: true,
        userId: userId,
        customerId: customerId
    }

    return postToken(endPoints.noteEndPoints.ADD_NOTE_URL, data)
}

// Not silme işlemi
const deleteNote = (noteId) => {
    return deleteToken(endPoints.noteEndPoints.DELETE_NOTE_URL(noteId))
}

// Not güncelleme işlemi
const updateNote = (form, planId, userId, customerId, noteId) => {
    let data = {
        plainText: form.plainText,
        planId: planId,
        isAdmin: true,
        userId: userId,
        customerId: customerId
    }
    return putToken(endPoints.noteEndPoints.UPDATE_NOTE_URL(noteId), data)
}


export const noteApi = {
    getEmployeeNoteList,
    getAdminNoteList,
    updateFavoriteNote,
    addNote,
    deleteNote,
    updateNote
}