const BASE_FILE = 'File';

// Alınan resmin formatını değiştirme url
const GET_FILE_ROUTE_URL = `${BASE_FILE}/GetFile`

// Yapılan satışın makbuzunu gösteren url
const GET_SALE_RECEIPT_URL = (saleId) => `${BASE_FILE}/GetReceipt?saleId=${saleId}`

export const fileEndPoints = {
    GET_FILE_ROUTE_URL,
    GET_SALE_RECEIPT_URL
}
