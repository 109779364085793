const BASE_PAYMENT_METHOD = 'PaymentMethod';

// Ödeme ayarlarının detayını getiren url
const GET_PAYMENT_SETTING_DETAIL_URL = (payType) => `${BASE_PAYMENT_METHOD}?payType=${payType}`

// Ödeme ayarlarının aktifliğini değişitrme  url
const UPDATE_PAYMENT_SETTING_ACTIVE_URL = (paymentId) => `${BASE_PAYMENT_METHOD}/${paymentId}`

export const paymentMethodEndPoints = {
    GET_PAYMENT_SETTING_DETAIL_URL,
    UPDATE_PAYMENT_SETTING_ACTIVE_URL
}
