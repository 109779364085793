export const parasutAccount = {
    state: {
        parasutAccounts: [],
        selectedAccount: {},
        selectParasutAccounts: []
    },
    getters: {
        getParasutAccounts: (state) => {
            const index = state.parasutAccounts.filter((item) => item.id === state.selectedAccount.id)
            return index
        },
        getSelectParasutAccounts: (state) => {
            return state.selectParasutAccounts
        },
        getSelectedAccount: (state) => {
            return state.selectedAccount
        }
    },
    mutations: {
        SET_PARASUT_ACCOUNTS(state, payload) {
            state.parasutAccounts = payload
            state.selectParasutAccounts = payload
        },
        SET_SELECTED_PARASUT_ACCOUNT(state, payload) {
            state.selectedAccount = payload
        }
    },
    actions: {
        SET_PARASUT_ACCOUNTS_ACTION({ commit }, payload) {
            commit('SET_PARASUT_ACCOUNTS', payload)
        },
        SET_SELECTED_PARASUT_ACCOUNT_ACTION({ commit }, payload) {
            commit('SET_SELECTED_PARASUT_ACCOUNT', payload)
        }
    },
    modules: {}
}
