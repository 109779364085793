import endPoints from "../EndPoint"
import { deleteToken } from "../requests/deleteToken"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"
import { putToken } from "../requests/putToken"

// Kurala bağlı ürün veya ürün kategorilerini listeler
const getExtraProductList = (extraProductRuleId, page, limit) => {
    return getToken(endPoints.extraProductEndPoints.GET_EXTRA_PRODUCT_LIST_URL(extraProductRuleId, page, limit))
}

// Kurala bağlı ürün veya ürün kategorilerini listeler
const updateExtraProductType = (productId, productType) => {
    let data = {
        'chooseType': productType
    }
    return putToken(endPoints.extraProductEndPoints.UPDATE_EXTRA_PRODUCT_TYPE_URL(productId), data)
}

// Kurala bağlı ürün veya ürün kategorilerini siler
const deleteExtraProduct = (productId) => {
    return deleteToken(endPoints.extraProductEndPoints.DELETE_EXTRA_PRODUCT_URL(productId))
}

// Kurala bağlı ürün veya ürün grubu ekler
const addExtraProduct = async (extraProductRuleId, form, ruleType) => {
    let data = form.selected.map((item) => {
        return {
            "extraProductRuleId": extraProductRuleId,
            "objectId": item.id,
            "chooseType": form.productType,
            "objectType": ruleType == "Product" ||
                ruleType == "SpecialCustomer" || ruleType == "NewCustomer" ||
                ruleType == "Balance" ? 'Product' : ruleType,
            "limit": form.limit,
            "maxLimit": form.maxLimit,
            "minLimit": form.minLimit,
            "name": item.name
        }
    })

    return await Promise.all(data.map(async (item) => {
        const response = await postToken(endPoints.extraProductEndPoints.ADD_EXTRA_PRODUCT_URL, item);
        return { ...response, item }
    }))
}

export const extraProductApi = {
    getExtraProductList,
    updateExtraProductType,
    deleteExtraProduct,
    addExtraProduct
}