import { returnActiveParasutAccount } from "@/helpers/ReturnActiveParasutAccount";

const BASE_REGION = 'Region';


// let parasutAccountId = returnActiveParasutAccount()

// Tüm bölgeleri listeleme url
const GET_REGIONS = (page = 1, limit = 10, search = '') => `${BASE_REGION}?page=${page}&limit=${limit}&search=${search}&parasutAccountId=${returnActiveParasutAccount()}`

// Bölge ekleme url
const ADD_REGION = `${BASE_REGION}`

// Bölge güncelleme url
const UPDATE_REGION = (regionId) => `${BASE_REGION}/${regionId}`

// Bölge bilgi getirme url
const GET_REGION_INFO = (regionId) => `${BASE_REGION}/${regionId}`

// Bölge silme url
const DELETE_REGION = (regionId) => `${BASE_REGION}/${regionId}`

// Bölgenin detaylarını çekme url (Bağlı olduğu çalışanlar)
const GET_REGION_DETAIL_URL = (page = 1, limit = 10, search = '') => `${BASE_REGION}/Details?page=${page}&limit=${limit}&search=${search}&parasutAccountId=${returnActiveParasutAccount()}`

export const regionEndPoints = {
    GET_REGIONS,
    ADD_REGION,
    UPDATE_REGION,
    GET_REGION_INFO,
    DELETE_REGION,
    GET_REGION_DETAIL_URL
}