const BASE_GOOGLE_MAP = 'GoogleMap';

// Haritada arama yaptıgımızda adrese göre veri getiren url
const GET_GOOGLE_MAP_ADDRESS_URL = (address = '') => `${BASE_GOOGLE_MAP}/GetAddressWithAddress?address=${address}`;

const GET_GOOGLE_MAP_LOCATION_URL = (location = '') => `${BASE_GOOGLE_MAP}/GetAddressWithCoordinate?latitude=${location.lat}&longitude=${location.lng}`;

export const googleMapEndPoints = {
    GET_GOOGLE_MAP_ADDRESS_URL,
    GET_GOOGLE_MAP_LOCATION_URL
}
