<template>
  <div id="app" class="col-12">
    <router-view />
  </div>
</template>

<script>
import localStorageManager from "./services/localStorageManager";
import { decrypto } from "./utils/Crypto";
export default {
  name: "App",
  mounted() {
    const activeuser = localStorageManager.adminStorage.getActiveUser();
    if (activeuser) {
      const decryptoData = decrypto(activeuser);
      this.$store.commit("SET_ACTIVE_USER", decryptoData);
    }
    // const img = document.getElementsByTagName('img');

    // img.onerror = ''
    // img.src = require('../src/assets/img/avatar.jpg')
  },
};
</script>

<style>
</style>
