const BASE_DISCOUNT = 'Discount';

// Ödeme yönteminin detayını getirme url
const GET_PAYMENT_METHOD_DETAIL_URL = (paymentMethodId) => `${BASE_DISCOUNT}?paymentMethodId=${paymentMethodId}`

// Ödeme yöntemini güncelleme url
const UPDATE_PAYMENT_METHOD_DETAIL_URL = (discountId) => `${BASE_DISCOUNT}/${discountId}`

// Ödeme yöntemini ekleme url
const ADD_PAYMENT_METHOD_URL = `${BASE_DISCOUNT}`

// Ödeme yöntemini silme url
const DELETE_PAYMENT_METHOD_URL = (discountId) => `${BASE_DISCOUNT}/${discountId}`

export const discountEndPoints = {
    GET_PAYMENT_METHOD_DETAIL_URL,
    UPDATE_PAYMENT_METHOD_DETAIL_URL,
    ADD_PAYMENT_METHOD_URL,
    DELETE_PAYMENT_METHOD_URL
}
