const BASE_PLAN_INTERVAL = 'PlanInterval';

// Tekrarlayan planları listeleme url
const GET_PLANS_INTERVAL_URL = (page = 1, limit = 10) => `${BASE_PLAN_INTERVAL}?page=${page}&limit=${limit}`

// Tekrarlayan plan ekleme url
const ADD_PLAN_INTERVAL_URL = `${BASE_PLAN_INTERVAL}`

// Tekrarlayan plan güncelleme url
const UPDATE_PLAN_INTERVAL_URL = (planId) => `${BASE_PLAN_INTERVAL}/${planId}`

// Tekrarlayan plan silen url
const DELETE_PLAN_URL = (planId) => `${BASE_PLAN_INTERVAL}/${planId}`

export const planIntervalEndPoints = {
    GET_PLANS_INTERVAL_URL,
    ADD_PLAN_INTERVAL_URL,
    UPDATE_PLAN_INTERVAL_URL,
    DELETE_PLAN_URL
}
