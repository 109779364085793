import endPoints from "../EndPoint";
import { deleteToken } from "../requests/deleteToken";
import { getToken } from "../requests/getToken";
import { postToken } from "../requests/postToken";
import { putToken } from "../requests/putToken";

// Bölgelerin listelenmesi
const getBankAccountsList = () => {
  return getToken(endPoints.bankAccountEndPoints.GET_BANK_ACCOUNTS);
};
// Bölgelerin listelenmesi
const getMikroBankAccountsList = () => {
  return getToken(endPoints.bankAccountEndPoints.GET_BANK_ACCOUNTS_OF_MIKRO);
};

// Bölge ekleme
const addBankAccount = (form) => {
  let data = {
    name: form.name,
  };
  return postToken(endPoints.bankAccountEndPoints.ADD_BANK_ACCOUNT, data);
};

// Bölge güncelleme
const updateBankAccount = (form, bankAccountId) => {
  let data = {
    name: form.name,
    isActive: form.isActive,
  };
  return putToken(
    endPoints.bankAccountEndPoints.UPDATE_BANK_ACCOUNT(bankAccountId),
    data
  );
};

// Bölgeye ait bilgi getirme
const getBankAccountInfo = (bankAccountId) => {
  return getToken(
    endPoints.bankAccountEndPoints.GET_BANK_ACCOUNT_INFO(bankAccountId)
  );
};

// Bölge silme
const deleteBankAccount = (bankAccountId) => {
  return deleteToken(
    endPoints.bankAccountEndPoints.DELETE_BANK_ACCOUNT(bankAccountId)
  );
};

// Bölgenin detaylarını çekme url (Bağlı olduğu çalışanlar)
const getBankAccountDetail = (page, limit, search) => {
  return getToken(
    endPoints.bankAccountEndPoints.GET_BANK_ACCOUNT_DETAIL_URL(
      page,
      limit,
      search
    )
  );
};

export const bankAccountApi = {
  getBankAccountsList,
  getMikroBankAccountsList,
  addBankAccount,
  updateBankAccount,
  getBankAccountInfo,
  deleteBankAccount,
  getBankAccountDetail,
};
