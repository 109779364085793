const BASE_NOTE = 'Note';

// Çalışanın müşteriye atmış oldupu notların listesi url
const GET_EMPLOYEE_NOTE_LIST_URL = (customerId = '', page = 1, limit = 10, planId = '', date = '', employeeId = '') => `${BASE_NOTE}/GetByUser?customerId=${customerId}&page=${page}&limit=${limit}&planId=${planId}&date=${date}&employeeId=${employeeId}`

// Adminin atmış olduğu notların listesi url
const GET_ADMIN_NOTE_LIST_URL = (customerId = '', adminId = '', page = 1, limit = 10, planId = '', employeeId = '') => `${BASE_NOTE}?customerId=${customerId}&adminId=${adminId}&page=${page}&limit=${limit}&planId=${planId}&employeeId=${employeeId}`

// Notlara favori ekleme url
const UPDATE_FAVORITE_NOTE_URL = (noteId) => `${BASE_NOTE}/${noteId}`

// Not ekleme url 
const ADD_NOTE_URL = `${BASE_NOTE}`

// Not güncelleme url 
const UPDATE_NOTE_URL = (noteId) => `${BASE_NOTE}/${noteId}`

// Not silme url 
const DELETE_NOTE_URL = (noteId) => `${BASE_NOTE}/${noteId}`

export const noteEndPoints = {
    GET_EMPLOYEE_NOTE_LIST_URL,
    GET_ADMIN_NOTE_LIST_URL,
    UPDATE_FAVORITE_NOTE_URL,
    ADD_NOTE_URL,
    DELETE_NOTE_URL,
    UPDATE_NOTE_URL
}
