import endPoints from "../EndPoint";
import { getToken } from "../requests/getToken";
import { postToken } from "../requests/postToken";

// Bölgelerin listelenmesi
const getTransactionsList = (query) => {
  return getToken(endPoints.transactionsEndPoints.GET_TRANSACTIONS(query));
};

// Bölge ekleme
const addTransaction = (form) => {
  return postToken(endPoints.transactionsEndPoints.ADD_TRANSACTIONS, form);
};

// Bölge ekleme
const addTransactionToMikro = (form) => {
  return postToken(endPoints.transactionsEndPoints.ADD_TRANSACTIONS_TO_MIKRO, form);
};

// Bölgeye ait bilgi getirme
const getTransactionInfo = (transactionId) => {
  return getToken(
    endPoints.transactionsEndPoints.GET_TRANSACTIONS_INFO(transactionId)
  );
};

// Bölge silme
const deleteTransaction = (transactionForm) => {
  return postToken(
    endPoints.transactionsEndPoints.DELETE_TRANSACTIONS(),
    transactionForm
  );
};

// Bölge silme
const getUserPaidTransactionBalance = (query = "") => {
  return getToken(
    endPoints.transactionsEndPoints.GET_USER_PAID_TRANSACTION_BALANCE(query)
  );
};

export const transactionsApi = {
  getTransactionsList,
  addTransaction,
  addTransactionToMikro,
  getTransactionInfo,
  getUserPaidTransactionBalance,
  deleteTransaction,
};
