import { baseUrl } from '../BaseUrl';

/** Post without token */
export const postNoToken = (url, data) => {
    const axios = require('axios');

    let config = {
        method: 'post',
        url: `${baseUrl}/${url}`,
        headers: {
            "Content-type": "application/json",
        },
        data: data
    }
    return axios(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            // localStorageManager.userAuth.removeActiveUser();
            // window.location.href = '/login'
            return error;
        });
}