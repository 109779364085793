import api from "@/services";

export const employee = {
  state: {
    status: "uninitialized",
    employees: [],
  },
  getters: {
    getEmployeeListStatus(state) {
      return state.status;
    },
    getEmployees: (state) => {
      return state.employees;
    },
  },
  mutations: {
    SET_EMPLOYEE_LOADING(state, payload) {
      state.status = payload || "uninitialized";
    },
    SET_EMPLOYEES(state, payload) {
      console.log(payload);
      state.employees = payload.filter(
        (p) => p.region?.id !== "659ea74fe4404fd7c89beb82"
      );
    },
  },
  actions: {
    async GET_ALL_EMPLOYEE_LIST({ commit, state }) {
      if (state.status === "success" && state.employees.length > 0)
        return state.employees;
      commit("SET_EMPLOYEE_LOADING", "loading");
      const response = await api.employeeApi.getEmployeeList(1, 1000);
      if (response.message === "OK") {
        response.data.items.forEach(async (item) => {
          if (item.image) {
            const image = await api.fileApi.fileRoute(item.image);
            item.image = image.src;
          }
          return { ...item };
        });
        commit("SET_EMPLOYEES", response.data.items);
        commit("SET_EMPLOYEE_LOADING", "success");
        return response.data.items;
      } else commit("SET_EMPLOYEES", []);
      commit("SET_EMPLOYEE_LOADING", "success");
      return [];
    },
    SET_EMPLOYEES_ACTION({ commit }, payload) {
      commit("SET_EMPLOYEES", payload);
    },
    GET_EMPLOYEE({ state }, payload) {
      return state.employees.find((emp) => emp.id === payload) || null;
    },
  },
  modules: {},
};
