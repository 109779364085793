import endPoints from "../EndPoint"
import { getToken } from "../requests/getToken"

// Adres vererek konumun bilgilerini alma
const getMapAddresList = (address) => {
    return getToken(endPoints.googleMapEndPoints.GET_GOOGLE_MAP_ADDRESS_URL(address))
}

// Lat-lng vererek konum bilgilerini alma
const getMapLocation = (location) => {
    return getToken(endPoints.googleMapEndPoints.GET_GOOGLE_MAP_LOCATION_URL(location))
}

export const googleMapApi = {
    getMapAddresList,
    getMapLocation
}