const BASE_ADMIN = 'Admin';

// Giriş yapma url
const LOGIN_URL = `${BASE_ADMIN}/Login`

//Tüm adminleri listeleme url
const GET_ADMIN_LIST_URL = (page = 1, limit = 10, roleType = '') => `${BASE_ADMIN}?page=${page}&limit=${limit}&roleType=${roleType}`

// Admin ya da muhasabeci ekleme url
const ADD_ADMIN_URL = `${BASE_ADMIN}/Register`

// Admin ya da muhasebeci güncelleme url
const UPDATE_ADMIN_URL = (userId) => `${BASE_ADMIN}/${userId}`

// Admin ya da muhasebeci bilgi getirme url
const GET_ADMIN_INFO_URL = (userId) => `${BASE_ADMIN}/${userId}`

// Admin ya da muhasebeci silme url
const DELETE_ADMIN_URL = (userId) => `${BASE_ADMIN}/${userId}`

// Admin ya da muhasebeci şifre unutma email gönderimi url
const FORGOT_PASSWORD_ADMIN_URL = `${BASE_ADMIN}/ForgetPassword`

// Admin ya da muhasebeci şifre değiştirme url
const RESET_PASSWORD_ADMIN_URL = `${BASE_ADMIN}/ResetPassword`

export const adminEndPoints = {
    LOGIN_URL,
    GET_ADMIN_LIST_URL,
    ADD_ADMIN_URL,
    UPDATE_ADMIN_URL,
    GET_ADMIN_INFO_URL,
    DELETE_ADMIN_URL,
    FORGOT_PASSWORD_ADMIN_URL,
    RESET_PASSWORD_ADMIN_URL
}