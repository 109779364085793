import endPoints from "../EndPoint"
import { deleteToken } from "../requests/deleteToken"
import { getToken } from "../requests/getToken"
import { postNoToken } from "../requests/postNoToken"
import { postToFormDataToken } from "../requests/postToFormDataToken"
import { putToFormDataToken } from "../requests/putToFormDataToken"

// Çalışanları listeleme
const getEmployeeList = (page = 1, limit = 10, searchText = '') => {
    return getToken(endPoints.employeeEndPoints.GET_EMPLOYEE_LIST_URL(page, limit, searchText))
}

// Çalışan ekleme
const addEmployee = (form) => {
    let data = new FormData();

    data.append('Name', form.name);
    data.append('Surname', form.surname);
    data.append('PhoneNo', form.phoneNo.length === 10 ? `+90${form.phoneNo}` : form.phoneNo.length === 11 ? `+9${form.phoneNo}` : form.phoneNo);
    data.append('Email', form.email);
    data.append('RegionId', form.region.id);
    data.append('Image', form.image);
    data.append('WorkStartTime', form.workStartTime);
    data.append('WorkFinishTime', form.workFinishTime);

    return postToFormDataToken(endPoints.employeeEndPoints.ADD_EMPLOYEE_URL, data)
}

// Çalışan güncelleme
const updateEmployee = (form, employeeId) => {
    let data = new FormData();

    data.append('Name', form.name);
    data.append('Surname', form.surname);
    data.append('PhoneNo', form.phoneNo.length === 10 ? `+90${form.phoneNo}` : form.phoneNo.length === 11 ? `+9${form.phoneNo}` : form.phoneNo);
    data.append('Email', form.email);
    data.append('RegionId', form.region.id);
    data.append('Image', form.image);
    data.append('WorkStartTime', form.workStartTime);
    data.append('WorkFinishTime', form.workFinishTime);

    return putToFormDataToken(endPoints.employeeEndPoints.UPDATE_EMPLOYEE_URL(employeeId), data)
}

// Çalışan bilgisi getirme
const getEmployeeInfo = (employeeId) => {

    return getToken(endPoints.employeeEndPoints.GET_EMPLOYEE_INFO_URL(employeeId))
}

// Çalışan silme
const deleteEmployee = (employeeId) => {
    return deleteToken(endPoints.employeeEndPoints.DELETE_EMPLOYEE_URL(employeeId))
}

// Çalışanı şifre ile kayıt etme durumu
const registerEmployee = (form, token) => {
    let data = {
        'token': token,
        'password': form.password
    }
    return postNoToken(endPoints.employeeEndPoints.REGISTER_EMPLOYEE_URL, data)
}

// Çalışan şifre değiştirme
const resetEmployeePassword = (form, token) => {
    let data = {
        'token': token,
        'password': form.password
    }
    return postNoToken(endPoints.employeeEndPoints.RESET_PASSWORD_EMPLOYEE_URL, data)
}

export const employeeApi = {
    getEmployeeList,
    addEmployee,
    updateEmployee,
    getEmployeeInfo,
    deleteEmployee,
    registerEmployee,
    resetEmployeePassword
}