import VueI18n from 'vue-i18n';
import Vue from 'vue';
import tr from '../src/locales/tr.json'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'tr',
    messages: { 'tr': tr },
});

export default i18n;