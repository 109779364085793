import VueRouter from "vue-router";
import Vue from "vue";
import localStorageManager from "@/services/localStorageManager";

Vue.use(VueRouter);

// kullanıcı yetki kontrolü
const beforeUserEnter = (to, from, next) => {
  const user = localStorageManager.adminStorage.getActiveUser();
  //here you can set if condition
  if (user !== null) {
    //redirect to other route
    return next();
  } else {
    return next({ path: "/login" });
  }
};

const routes = [
  {
    path: "/",
    name: "mainLayout",
    component: () => import("@/layouts/defaultLayout.vue"),
    beforeEnter: beforeUserEnter,
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () => import("@/pages/DashboardPage.vue"),
        redirect: "/plans",
      },
      {
        path: "/plans",
        name: "plan",
        component: () => import("@/pages/PlansPage.vue"),
      },
      {
        path: "/customers",
        name: "customers",
        component: () => import("@/pages/CustomersPage.vue"),
      },
      {
        path: "/customer-detail/:id",
        name: "customerDetail",
        component: () => import("@/pages/CustomerDetailPage.vue"),
      },
      {
        path: "/employees",
        name: "employees",
        component: () => import("@/pages/EmployeesPage.vue"),
      },
      {
        path: "/employee-detail/:id",
        name: "employeeDetail",
        component: () => import("@/pages/EmployeeDetailPage.vue"),
      },
      {
        path: "/add-plan",
        name: "addPlan",
        component: () => import("@/pages/AddPlanPage.vue"),
      },
      {
        path: "/admin-accountantan",
        name: "adminAccountant",
        component: () => import("@/pages/AdminAccountantPage.vue"),
      },
      {
        path: "/regions",
        name: "regions",
        component: () => import("@/pages/RegionsPage.vue"),
      },
      {
        path: "/region-customers/:id",
        name: "regionCustomers",
        component: () => import("@/pages/RegionCustomersPage.vue"),
      },
      {
        path: "/task-detail/:id",
        name: "taskDetail",
        component: () => import("@/pages/TaskDetailPage.vue"),
      },
      {
        path: "/products",
        name: "products",
        component: () => import("@/pages/ProductsPage.vue"),
      },
      {
        path: "/product-detail/:id",
        name: "productDetail",
        component: () => import("@/pages/ProductDetailPage.vue"),
      },
      {
        path: "/product-category",
        name: "productCategory",
        component: () => import("@/pages/ProductCategoryPage.vue"),
      },
      {
        path: "/product-category-detail/:id",
        name: "productCategoryDetail",
        component: () => import("@/pages/ProductCategoryDetailPage.vue"),
      },
      {
        path: "/extra-product-rules",
        name: "extraProductRules",
        component: () => import("@/pages/ExtraProductRulesPage.vue"),
      },
      {
        path: "/extra-product-rule-detail/:id",
        name: "extraProductRuleDetail",
        component: () => import("@/pages/ExtraProductRuleDetailPage.vue"),
      },
      {
        path: "/sales",
        name: "sales",
        component: () => import("@/pages/SalesPage.vue"),
      },
      {
        path: "/notes",
        name: "notes",
        component: () => import("@/pages/NotesPage.vue"),
      },
      {
        path: "/bank-accounts",
        name: "bank-accounts",
        component: () => import("@/pages/BankAccountsPage.vue"),
      },
      {
        path: "/plan-settings",
        name: "planSettings",
        component: () => import("@/pages/PlanSettingsPage.vue"),
      },
      {
        path: "/payment-settings",
        name: "paymentSettings",
        component: () => import("@/pages/PaymentSettingsPage.vue"),
      },
      {
        path: "/payment-setting-detail/:payType",
        name: "paymentSettingsDetail",
        component: () => import("@/pages/PaymentSettingsDetailPage.vue"),
      },
      {
        path: "/payment-method/:id",
        name: "paymentMethod",
        component: () => import("@/pages/PaymentMethodPage.vue"),
      },
      {
        path: "/special-discount",
        name: "specialDiscount",
        component: () => import("@/pages/SpecialDiscountPage.vue"),
      },
      {
        path: "/sale-detail/:id",
        name: "saleDetail",
        component: () => import("@/pages/SaleDetailPage.vue"),
      },
      {
        path: "/transaction/:id",
        name: "transaction",
        component: () => import("@/pages/TransactionPage.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/LoginPage.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import("@/pages/ForgotPasswordPage.vue"),
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: () => import("@/pages/ResetPasswordPage.vue"),
  },
  {
    path: "/employee-register",
    name: "employeeRegister",
    component: () => import("@/pages/EmployeeRegisterPage.vue"),
  },
  {
    path: "/receipt",
    name: "receipt",
    component: () => import("@/pages/ReceiptPage.vue"),
  },
  {
    path: "/receipt/:id",
    name: "receiptTableView",
    component: () => import("@/pages/ReceiptTableViewPage.vue"),
  },
  {
    path: "*",
    name: "error",
    component: () => import("@/pages/ErrorPage.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
