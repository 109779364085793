const BASE_PAYMENT_SETTING = 'PaymentSetting';

// Ödeme ayarlarını listeleyen url
const GET_PAYMENT_SETTING_URL = `${BASE_PAYMENT_SETTING}`

// Ödeme ayarlarını güncelleyen url
const UPDATE_PAYMENT_SETTING_URL = (paymentId) => `${BASE_PAYMENT_SETTING}/${paymentId}`

export const paymentSettingEndPoints = {
    GET_PAYMENT_SETTING_URL,
    UPDATE_PAYMENT_SETTING_URL,
}
