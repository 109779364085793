import router from "@/router/Router";
import localStorageManager from "@/services/localStorageManager";

export const admin = {
    state: {
        activeUser: {}
    },
    getters: {
        getActiveUser: (state) => {
            return state.activeUser
        }
    },
    mutations: {
        SET_ACTIVE_USER(state, payload) {
            state.activeUser = payload
        },
    },
    actions: {
        SET_LOGOUT_ACTION(state) {
            state.activeUser = {};
            localStorageManager.adminStorage.removeUser();
            router.push('/login')
        }
    },
    modules: {}
}
