import router from '@/router/Router';
import { baseUrl } from '../BaseUrl';
import localStorageManager from '../localStorageManager';

/** Put token */
export const putToken = (url, data) => {
    const token = localStorageManager.adminStorage.getToken()
    const axios = require('axios');

    let config = {
        method: 'put',
        url: `${baseUrl}/${url}`,
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
        data: data
    }
    return axios(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const err = error.response
            if (err.status === 401 || err.status === 403) {
                localStorageManager.adminStorage.removeUser()
                router.push('/login')
            }
            return error;
        });
}