import endPoints from "../EndPoint"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"

// Paraşütteki çalışan listesini getirir.
const getParasutUserList = (parasutAccountId, page = 1, limit = 20) => {
    return getToken(endPoints.parasutSyncEndPoints.GET_PARASUT_USER_LIST(parasutAccountId, page, limit))
}

// Paraşütteki bölgeler listesini getirir.
const getParasutRegionList = (parasutAccountId, page = 1, limit = 20) => {
    return getToken(endPoints.parasutSyncEndPoints.GET_PARASUT_REGION_LIST_URL(parasutAccountId, page, limit))
}

// Paraşütteki müşteriler listesini getirir.
const getParasutCustomerList = (parasutAccountId, page = 1, limit = 20, search) => {
    return getToken(endPoints.parasutSyncEndPoints.GET_PARASUT_CUSTOMER_LIST_URL(parasutAccountId, page, limit, search))
}
// Paraşütteki ürün kategorilerinin listesini getirir.
const getParasutProductCategoryList = (parasutAccountId, page = 1, limit = 20) => {
    return getToken(endPoints.parasutSyncEndPoints.GET_PARASUT_PRODUCT_CATEGORY_LIST_URL(parasutAccountId, page, limit))
}

// Paraşütteki ürünlerin listesini getirir.
const getParasutProductList = (parasutAccountId, page = 1, limit = 20, search = "") => {
    return getToken(endPoints.parasutSyncEndPoints.GET_PARASUT_PRODUCT_LIST_URL(parasutAccountId, page, limit, search))
}

const transferAttributes = (form, formObject, url, relationships) => {
    let request = {
        data: {
            id: form.user.id,
            attributes: formObject,
            relationships
        },
    }
    return postToken(endPoints.parasutSyncEndPoints.TRANSFER_ATTRIBUTES(form.company.id, url), request)
}

export const parasutSyncApi = {
    getParasutUserList,
    transferAttributes,
    getParasutRegionList,
    getParasutCustomerList,
    getParasutProductCategoryList,
    getParasutProductList
}