import { returnActiveParasutAccount } from "@/helpers/ReturnActiveParasutAccount";

const BASE_EMPLOYEE = 'Employee';

// let parasutAccountId = returnActiveParasutAccount()

// Çalışanları listeleme url
const GET_EMPLOYEE_LIST_URL = (page = 1, limit = 10, searchText = '', regionId = '') => `${BASE_EMPLOYEE}?page=${page}&limit=${limit}&searchKey=${searchText}&regionId=${regionId}&parasutAccountId=${returnActiveParasutAccount()}`

// Çalışan ekleme url
const ADD_EMPLOYEE_URL = `${BASE_EMPLOYEE}`

// Çalışan güncelleme url
const UPDATE_EMPLOYEE_URL = (employeeId) => `${BASE_EMPLOYEE}/${employeeId}`

// Çalışan bilgisi getirme url
const GET_EMPLOYEE_INFO_URL = (employeeId) => `${BASE_EMPLOYEE}/${employeeId}`

// Çalışan silme url
const DELETE_EMPLOYEE_URL = (employeeId) => `${BASE_EMPLOYEE}/${employeeId}`

// Çalışan kayıt şifre belirleme url
const REGISTER_EMPLOYEE_URL = `${BASE_EMPLOYEE}/RegisterWithPassword`

// Çalışan şifre değiştirme url
const RESET_PASSWORD_EMPLOYEE_URL = `${BASE_EMPLOYEE}/ResetPassword`


export const employeeEndPoints = {
    GET_EMPLOYEE_LIST_URL,
    ADD_EMPLOYEE_URL,
    UPDATE_EMPLOYEE_URL,
    GET_EMPLOYEE_INFO_URL,
    DELETE_EMPLOYEE_URL,
    REGISTER_EMPLOYEE_URL,
    RESET_PASSWORD_EMPLOYEE_URL
}
