import endPoints from "../EndPoint";
import { deleteToken } from "../requests/deleteToken";
import { getToken } from "../requests/getToken";
import { postNoToken } from "../requests/postNoToken"
import { postToken } from "../requests/postToken";
import { putToken } from "../requests/putToken";

// Giriş yapma
const login = (form) => {
    let data = {
        'username': form.type === 1 ? form.userName : form.phoneNo.length === 10 ? `+90${form.phoneNo}` : form.phoneNo.length === 11 ? `+9${form.phoneNo}` : form.phoneNo,
        'password': form.password
    }

    return postNoToken(endPoints.adminEndPoints.LOGIN_URL, data)
}

// Tüm adminleri ve muhasebecileri listeleler.
const getAdminsList = (page = 1, limit = 10, roleType) => {
    return getToken(endPoints.adminEndPoints.GET_ADMIN_LIST_URL(page, limit, roleType))
}

// Admin ya da muhasebeci ekleme
const addAdmin = (value) => {
    let data = {
        "name": value.name,
        "surname": value.surname,
        "email": value.email,
        "phoneNo": value.phoneNo.length === 10 ? `+90${value.phoneNo}` : value.phoneNo.length === 11 ? `+9${value.phoneNo}` : value.phoneNo,
        "password": value.password,
        "role": value.role
    }
    return postToken(endPoints.adminEndPoints.ADD_ADMIN_URL, data)
}

// Admin ya da muhasebici güncelleme
const updateAdmin = (userId, form) => {
    let data = {
        "name": form.name,
        "surname": form.surname,
        "phoneNo": form.phoneNo.length === 10 ? `+90${form.phoneNo}` : form.phoneNo.length === 11 ? `+9${form.phoneNo}` : form.phoneNo,
        "email": form.email,
        "password": form.password,
        "isActive": true,
        "role": form.role
    }

    return putToken(endPoints.adminEndPoints.UPDATE_ADMIN_URL(userId), data)
}

// Admin ya da muhasebici istediğimiz bilgiyi güncelleme
const updateInfoAdmin = (userId, form) => {
    let data = form

    return putToken(endPoints.adminEndPoints.UPDATE_ADMIN_URL(userId), data)
}

// Admin ya da muhasabeci bilgisi getirme
const getAdminInfo = (userId) => {
    return getToken(endPoints.adminEndPoints.GET_ADMIN_INFO_URL(userId))
}

// Admin ya da muhasebeci silme
const deleteAdmin = (userId) => {
    return deleteToken(endPoints.adminEndPoints.DELETE_ADMIN_URL(userId))
}

// Admin ya da muhasebeci şifre unutma email gönderimi
const forgotPassword = (form) => {
    let data = {
        'username': form.email
    }
    return postNoToken(endPoints.adminEndPoints.FORGOT_PASSWORD_ADMIN_URL, data)
}

// Admin ya da muhasebeci şifre değiştirme
const resetPassword = (form, token) => {
    let data = {
        'token': token,
        'password': form.password
    }
    return postNoToken(endPoints.adminEndPoints.RESET_PASSWORD_ADMIN_URL, data)
}

export const adminApi = {
    login,
    getAdminsList,
    addAdmin,
    updateAdmin,
    getAdminInfo,
    deleteAdmin,
    updateInfoAdmin,
    forgotPassword,
    resetPassword
}