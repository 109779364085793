import endPoints from "../EndPoint"
import { deleteToken } from "../requests/deleteToken"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"
import { putToken } from "../requests/putToken"

// Satışa ait notları listeleme
const getSaleNotes = (saleId = "", page, limit) => {
    return getToken(endPoints.saleNoteEndPoints.GET_SALE_NOTES_URL(saleId, page, limit))
}

// Satışa not ekleme
const addSaleNote = (saleId, userId, form) => {
    let data = {
        "plainText": form.plainText,
        "saleId": saleId,
        "userId": userId
    }
    return postToken(endPoints.saleNoteEndPoints.ADD_SALE_NOTE_URL, data)
}

// Şatışa atılan notları favoriye ekleme
const updateFavoriteNote = (saleNoteId, isFavourite) => {
    let data = {
        'isFavourite': !isFavourite,
    }

    return putToken(endPoints.saleNoteEndPoints.UPDATE_FAVORITE_SALE_NOTE_URL(saleNoteId), data)
}

// Şatışa atılan notları güncelleme
const updateSaleNote = (saleNoteId, form) => {
    let data = {
        "plainText": form.plainText,
    }

    return putToken(endPoints.saleNoteEndPoints.UPDATE_SALE_NOTE_URL(saleNoteId), data)
}

// Satışa ait notu silme
const deleteSaleNote = (saleNoteId) => {
    return deleteToken(endPoints.saleNoteEndPoints.DELETE_SALE_NOTE_URL(saleNoteId))
}

export const saleNoteApi = {
    getSaleNotes,
    addSaleNote,
    updateFavoriteNote,
    updateSaleNote,
    deleteSaleNote
}