import { returnActiveParasutAccount } from "@/helpers/ReturnActiveParasutAccount";

const BASE_PRODUCT = 'Product';

// let parasutAccountId = returnActiveParasutAccount()

// Tüm ürünleri listeleme url
const GET_PRODUCTS_URL = (productCategoryId = '', page = 1, limit = 10, search = '', productType = '') => `${BASE_PRODUCT}?page=${page}&limit=${limit}&parasutAccountId=${returnActiveParasutAccount()}&productCategoryId=${productCategoryId}&search=${search}&onlyProduct=${productType}`

// Ürün ekleme url
const ADD_PRODUCT_URL = `${BASE_PRODUCT}`

// Ürün silme url
const DELETE_PRODUCT_URL = (productId) => `${BASE_PRODUCT}/${productId}`

// Ürün detayı url
const GET_PRODUCT_DETAIL_URL = (productId) => `${BASE_PRODUCT}/${productId}`

// Ürünün aktifliğini değiştiren url
const UPDATE_PRODUCT_ACTIVE_URL = (productId) => `${BASE_PRODUCT}/${productId}`

// Ürünün önceliğini değiştiren url
const UPDATE_PRODUCT_PRIORITY_URL = (productId) => `${BASE_PRODUCT}/${productId}`

// Paket ve ürün güncellemesi yapan url
const UPDATE_PRODUCT_PACKAGES_URL = (packageId) => `${BASE_PRODUCT}/${packageId}`

export const productEndPoints = {
    GET_PRODUCTS_URL,
    ADD_PRODUCT_URL,
    DELETE_PRODUCT_URL,
    GET_PRODUCT_DETAIL_URL,
    UPDATE_PRODUCT_ACTIVE_URL,
    UPDATE_PRODUCT_PRIORITY_URL,
    UPDATE_PRODUCT_PACKAGES_URL
}
