const BASE_PLAN_DEFINITION = 'PlanDefinition';

// Normal plan ekleme url
const ADD_PLAN_DEFINITION_URL = `${BASE_PLAN_DEFINITION}`

// Normal planları listeleme url
const GET_PLANS_DEFINITION_URL = (page = 1, limit = 10) => `${BASE_PLAN_DEFINITION}?page=${page}&limit=${limit}`

// Normal planları silme url
const DELETE_PLANS_DEFINITION_URL = (planId) => `${BASE_PLAN_DEFINITION}/${planId}`

export const planDefinitionEndPoints = {
    ADD_PLAN_DEFINITION_URL,
    GET_PLANS_DEFINITION_URL,
    DELETE_PLANS_DEFINITION_URL
}
