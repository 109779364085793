import { returnActiveParasutAccount } from "@/helpers/ReturnActiveParasutAccount";

const BASE_SALE = "Sale";

// let parasutAccountId = returnActiveParasutAccount()

// Çalışanların yaptığı satışlar url
const GET_EMPLOYEE_SALE_LIST = (
  employeeId = "",
  page = 1,
  limit = 10,
  saleType = ""
) =>
  `${BASE_SALE}?employeeId=${employeeId}&page=${page}&limit=${limit}&saleType=${saleType}`;

// Müşteriye yapılan satışlar url
const GET_CUSTOMER_SALE_LIST = (
  customerId = "",
  page = 1,
  limit = 10,
  saleType = ""
) =>
  `${BASE_SALE}?customerId=${customerId}&page=${page}&limit=${limit}&saleType=${saleType}`;

// Plana ait yapılan satışlar url
const GET_PLAN_SALE_LIST_URL = (
  planId = "",
  page = 1,
  limit = 10,
  saleType = ""
) =>
  `${BASE_SALE}?planId=${planId}&page=${page}&limit=${limit}&saleType=${saleType}`;

// Tüm satışların listelendiği url
const GET_SALE_LIST_URL = (
  page = 1,
  limit = 10,
  saleType = "",
  search = "",
  employeeId = "",
  paymentType = "",
  payType = "",
  timeType = "",
  filterDate = null
) =>
  `${BASE_SALE}?page=${page}&limit=${limit}&saleType=${saleType}&paymentType=${paymentType}&employeeId=${employeeId}&searchQuery=${search}&parasutAccountId=${returnActiveParasutAccount()}&payType=${payType}&saleTimeFilter=${timeType}&date=${filterDate}`;

// Satış detayını getiren url
const GET_SALE_DETAIL_URL = (saleId = "") => `${BASE_SALE}/${saleId}`;

// Satışların statüsünü değiştiren url
const CHANGE_SALE_URL = `${BASE_SALE}/ChangeStatus`;

export const saleEndPoints = {
  GET_EMPLOYEE_SALE_LIST,
  GET_CUSTOMER_SALE_LIST,
  GET_PLAN_SALE_LIST_URL,
  GET_SALE_LIST_URL,
  CHANGE_SALE_URL,
  GET_SALE_DETAIL_URL,
};
