import api from "@/services";

export const regions = {
  state: {
    status: "uninitialized",
    regions: [],
  },
  getters: {
    getRegionsListStatus(state) {
      return state.status;
    },
    getRegions: (state) => {
      return state.regions;
    },
  },
  mutations: {
    SET_REGIONS_LOADING(state, payload) {
      state.status = payload || "uninitialized";
    },
    SET_REGIONS(state, payload) {
      console.log(payload);
      state.regions = payload;
    },
  },
  actions: {
    async GET_ALL_REGIONS_LIST({ commit, state }) {
      if (state.status === "success" && state.regions.length > 0)
        return state.regions;
      commit("SET_REGIONS_LOADING", "loading");
      console.log(state, "regions");
      const response = await api.regionApi.getRegionsList(1, 1000);
      if (response.message === "OK") {
        response.data.items.forEach(async (item) => {
          if (item.image) {
            const image = await api.fileApi.fileRoute(item.image);
            item.image = image.src;
          }
          return { ...item };
        });
        commit("SET_REGIONS", response.data.items);
        commit("SET_REGIONS_LOADING", "success");
        return response.data.items;
      } else commit("SET_REGIONS", []);
      commit("SET_REGIONS_LOADING", "success");
      return [];
    },
    SET_REGIONS_ACTION({ commit }, payload) {
      commit("SET_REGIONS", payload);
    },
  },
  modules: {},
};
