import { adminEndPoints } from "./endPoints/Admin";
import { regionEndPoints } from "./endPoints/Region";
import { bankAccountEndPoints } from "./endPoints/BankAccounts";
import { transactionsEndPoints } from "./endPoints/Transactions";
import { employeeEndPoints } from "./endPoints/Employee";
import { fileEndPoints } from "./endPoints/File";
import { employeeTrackingEndPoints } from "./endPoints/EmployeeTracking";
import { planEndPoints } from "./endPoints/Plan";
import { saleEndPoints } from "./endPoints/Sale";
import { customerEndPoints } from "./endPoints/Customer";
import { parasutAccountEndPoints } from "./endPoints/ParasutAccount";
import { parasutSyncEndPoints } from "./endPoints/ParasutSync";
import { noteEndPoints } from "./endPoints/Note";
import { productEndPoints } from "./endPoints/Product";
import { productCategoryEndPoints } from "./endPoints/ProductCategory";
import { extraProductRuleEndPoints } from "./endPoints/ExtraProductRule";
import { extraProductEndPoints } from "./endPoints/ExtraProduct";
import { planDefinitionEndPoints } from "./endPoints/PlanDefinition";
import { planIntervalEndPoints } from "./endPoints/PlanInterval";
import { paymentSettingEndPoints } from "./endPoints/PaymentSetting";
import { paymentMethodEndPoints } from "./endPoints/PaymentMethod";
import { discountEndPoints } from "./endPoints/Discount";
import { specialDiscountEndPoints } from "./endPoints/SpecialDiscount";
import { saleNoteEndPoints } from "./endPoints/SaleNote";
import { googleMapEndPoints } from "./endPoints/GoogleMap";

const endPoints = {
    adminEndPoints,
    regionEndPoints,
    transactionsEndPoints,
    bankAccountEndPoints,
    employeeEndPoints,
    fileEndPoints,
    employeeTrackingEndPoints,
    planEndPoints,
    saleEndPoints,
    customerEndPoints,
    parasutAccountEndPoints,
    parasutSyncEndPoints,
    noteEndPoints,
    productEndPoints,
    productCategoryEndPoints,
    extraProductRuleEndPoints,
    extraProductEndPoints,
    planDefinitionEndPoints,
    planIntervalEndPoints,
    paymentSettingEndPoints,
    paymentMethodEndPoints,
    discountEndPoints,
    specialDiscountEndPoints,
    saleNoteEndPoints,
    googleMapEndPoints
}

export default endPoints;