import endPoints from "../EndPoint"
import { deleteToken } from "../requests/deleteToken"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"
import { putToken } from "../requests/putToken"

const getPaymentMethodDetail = (paymentMethodId) => {
    return getToken(endPoints.discountEndPoints.GET_PAYMENT_METHOD_DETAIL_URL(paymentMethodId))
}

const updatePaymentMethod = (discountId, form) => {
    let data = form
    return putToken(endPoints.discountEndPoints.UPDATE_PAYMENT_METHOD_DETAIL_URL(discountId), data)
}

const addPaymentMethod = (paymentMethodId, form) => {
    let data = {
        'paymentMethodId': paymentMethodId,
        'discountRate': form.discountRate
    }
    return postToken(endPoints.discountEndPoints.ADD_PAYMENT_METHOD_URL, data)
}

const deletePaymentMethod = (discountId) => {
    return deleteToken(endPoints.discountEndPoints.DELETE_PAYMENT_METHOD_URL(discountId))
}

export const discountApi = {
    getPaymentMethodDetail,
    updatePaymentMethod,
    addPaymentMethod,
    deletePaymentMethod
}