import { adminApi } from './modules/Admin'
import { regionApi } from './modules/Region';
import { bankAccountApi } from './modules/BankAccounts';
import { employeeApi } from './modules/Employee';
import { fileApi } from './modules/File';
import { employeeTrackingApi } from './modules/EmployeeTracking';
import { planApi } from './modules/Plan';
import { saleApi } from './modules/Sale';
import { customerApi } from './modules/Customer';
import { parasutAccountApi } from './modules/ParasutAccount';
import { parasutSyncApi } from './modules/ParasutSync';
import { noteApi } from './modules/Note';
import { productApi } from './modules/Product';
import { productCategoryApi } from './modules/ProductCategory';
import { extraProductRuleApi } from './modules/ExtraProductRule';
import { extraProductApi } from './modules/ExtraProduct';
import { planDefinitionApi } from './modules/PlanDefinition';
import { planIntervalApi } from './modules/PlanInterval';
import { paymentSettingApi } from './modules/PaymentSetting';
import { paymentMethodApi } from './modules/PaymentMethod';
import { discountApi } from './modules/Discount';
import { specialDiscountApi } from './modules/SpecialDiscount';
import { saleNoteApi } from './modules/SaleNote';
import { googleMapApi } from './modules/GoogleMap';
import { transactionsApi } from './modules/Transactions';

const api = {
    adminApi,
    regionApi,
    bankAccountApi,
    employeeApi,
    fileApi,
    employeeTrackingApi,
    planApi,
    saleApi,
    customerApi,
    parasutAccountApi,
    parasutSyncApi,
    noteApi,
    productApi,
    productCategoryApi,
    extraProductRuleApi,
    extraProductApi,
    planDefinitionApi,
    planIntervalApi,
    paymentSettingApi,
    paymentMethodApi,
    discountApi,
    specialDiscountApi,
    saleNoteApi,
    googleMapApi,
    transactionsApi
}

export default api;