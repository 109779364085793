const BASE_EXTRA_PRODUCT_RULE = 'ExtraProductRule';

// Ürün kategorisine bağlı kurallar url
const GET_CATEGORY_EXTRA_PRODUCT_RULE_URL = (productCategoryId = '', page = 1, limit = 10) => `${BASE_EXTRA_PRODUCT_RULE}?productCategoryId=${productCategoryId}&page=${page}&limit=${limit}`

// Ürüne bağlı kurallar url
const GET_EXTRA_PRODUCT_RULE_URL = (productId = '', page = 1, limit = 10) => `${BASE_EXTRA_PRODUCT_RULE}?productId=${productId}&page=${page}&limit=${limit}`

// Kural ekleme url
const ADD_EXTRA_PRODUCT_RULE_URL = `${BASE_EXTRA_PRODUCT_RULE}`

// Kural detay getirme url
const GET_DETAIL_EXTRA_PRODUCT_RULE_URL = (ruleId) => `${BASE_EXTRA_PRODUCT_RULE}/${ruleId}`

// Kural güncelleme url
const UPDATE_EXTRA_PRODUCT_RULE_URL = (ruleId) => `${BASE_EXTRA_PRODUCT_RULE}/${ruleId}`

// Kural aktiflik güncelleme url
const UPDATE_EXTRA_PRODUCT_RULE_ACTIVE_URL = (ruleId) => `${BASE_EXTRA_PRODUCT_RULE}/${ruleId}`

// Kural silme url
const DELETE_EXTRA_PRODUCT_RULE_URL = (ruleId) => `${BASE_EXTRA_PRODUCT_RULE}/${ruleId}`

export const extraProductRuleEndPoints = {
    GET_CATEGORY_EXTRA_PRODUCT_RULE_URL,
    GET_EXTRA_PRODUCT_RULE_URL,
    ADD_EXTRA_PRODUCT_RULE_URL,
    GET_DETAIL_EXTRA_PRODUCT_RULE_URL,
    UPDATE_EXTRA_PRODUCT_RULE_URL,
    UPDATE_EXTRA_PRODUCT_RULE_ACTIVE_URL,
    DELETE_EXTRA_PRODUCT_RULE_URL
}
