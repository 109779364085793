import endPoints from "../EndPoint"
import { deleteToken } from "../requests/deleteToken"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"
import { putToken } from "../requests/putToken"

// Ürün kategorisine bağlı kurallar
const getCategoryExtraProductRule = (productCategoryId, page, limit) => {
    return getToken(endPoints.extraProductRuleEndPoints.GET_CATEGORY_EXTRA_PRODUCT_RULE_URL(productCategoryId, page, limit))
}

// Ürüne bağlı kurallar
const getExtraProductRule = (productId, page, limit) => {
    return getToken(endPoints.extraProductRuleEndPoints.GET_EXTRA_PRODUCT_RULE_URL(productId, page, limit))
}

// Kural ekle
const addExtraProductRule = (form) => {
    let data = {
        "productCount": form.productCount,
        "productUnit": form.extraProductUnit,
        "extraProductCount": form.extraProductCount,
        "extraProductUnit": form.extraProductUnit,
        "description": form.description,
        "customerId": form.customer?.id,
        "isYearCustomer": form.isYearCustomer,
        "dayBalance": {
            "day": form.dayBalance.day,
            "balance": form.dayBalance.balance
        },
        "ruleType": form.ruleType,
        "ruleValidationDay": form.ruleValidationDay
    }
    return postToken(endPoints.extraProductRuleEndPoints.ADD_EXTRA_PRODUCT_RULE_URL, data)
}

// mal fazlası kuralı detay getirme
const getDetailExtraProductRule = (ruleId) => {
    return getToken(endPoints.extraProductRuleEndPoints.GET_DETAIL_EXTRA_PRODUCT_RULE_URL(ruleId))
}

const updateExtraProductRule = (ruleId, form) => {
    let data = {
        "productCount": form.productCount,
        "productUnit": form.extraProductUnit,
        "extraProductCount": form.extraProductCount,
        "extraProductUnit": form.extraProductUnit,
        "description": form.description,
        "customerId": form.customer?.id,
        "isYearCustomer": form.isYearCustomer,
        "dayBalance": {
            "day": form.dayBalance.day,
            "balance": form.dayBalance.balance
        },
        "ruleType": form.ruleType,
        "ruleValidationDay": form.ruleValidationDay
    }
    return putToken(endPoints.extraProductRuleEndPoints.UPDATE_EXTRA_PRODUCT_RULE_URL(ruleId), data)
}

const updateExtraProductRuleActive = (ruleId, activeType) => {
    let data = {
        'isActive': activeType
    }

    return putToken(endPoints.extraProductRuleEndPoints.UPDATE_EXTRA_PRODUCT_RULE_ACTIVE_URL(ruleId), data)
}

const deleteExtraProductRule = (ruleId) => {

    return deleteToken(endPoints.extraProductRuleEndPoints.DELETE_EXTRA_PRODUCT_RULE_URL(ruleId))
}

export const extraProductRuleApi = {
    getCategoryExtraProductRule,
    getExtraProductRule,
    addExtraProductRule,
    getDetailExtraProductRule,
    updateExtraProductRule,
    updateExtraProductRuleActive,
    deleteExtraProductRule
}