import endPoints from "../EndPoint"
import { getToken } from "../requests/getToken"
import { postToFormDataToken } from "../requests/postToFormDataToken"
import { putToFormDataToken } from "../requests/putToFormDataToken"

// Çalışana bağlı müşterilerin listelenmesi
const getEmployeeCustomers = (employeeInfo, page, limit, searchKey, lastPlanFilter, maxLongitude, maxLatitude, minLongitude, minLatitude) => {
    return getToken(endPoints.customerEndPoints.GET_EMPLOYEE_CUSTOMERS_URL(employeeInfo, page, limit, searchKey, lastPlanFilter, maxLongitude, maxLatitude, minLongitude, minLatitude))
}

// Tüm çalışanları listeler
const getCustomers = (page, limit, searchKey, lastPlanFilter, regionId, isYearCustomer, maxLongitude, maxLatitude, minLongitude, minLatitude) => {
    return getToken(endPoints.customerEndPoints.GET_CUSTOMERS_URL(page, limit, searchKey, lastPlanFilter, regionId, isYearCustomer, maxLongitude, maxLatitude, minLongitude, minLatitude))
}

// Tüm çalışanları listeler
const getCustomersList = (page, limit, searchKey, lastPlanFilter, regionId, isYearCustomer, userType) => {
    return getToken(endPoints.customerEndPoints.GET_CUSTOMERS_LIST_URL(page, limit, searchKey, lastPlanFilter, regionId, isYearCustomer, userType))
}

// Tüm çalışanları bölgeye göre listeler
const getRegionCustomers = (page, limit, regionId, searchKey) => {
    return getToken(endPoints.customerEndPoints.GET_REGION_CUSTOMERS_URL(page, limit, regionId, searchKey))
}

// Müşteri ekleme
const addCustomer = (form) => {
    let data = new FormData();

    data.append(`fullName`, form.fullName)
    data.append(`companyName`, form.companyName)
    data.append(`phoneNo`, form.phoneNo.length === 10 ? `+90${form.phoneNo}` : form.phoneNo.length === 11 ? `+9${form.phoneNo}` : form.phoneNo)
    data.append(`taxNo`, form.taxNo || '')
    data.append(`taxOffice`, form.taxOffice || '')
    data.append(`email`, form.email || '')
    data.append(`faxNo`, form.faxNo || '')
    data.append(`address.province`, form.address.province || '')
    data.append(`address.district`, form.address.district || '')
    data.append(`address.address`, form.address.formattedAddress || '')
    data.append(`address.latitude`, form.address.latitude || '')
    data.append(`address.longitude`, form.address.longitude || '')
    data.append(`address.buildingNo`, form.address.buildingNo || '')
    data.append(`address.floorNo`, form.address.floorNo || '')
    data.append(`address.apartmentNo`, form.address.apartmentNo || '')
    data.append(`address.country`, form.address.country || '')
    data.append(`address.postalCode`, form.address.postalCode || '')
    data.append(`shippingAddress.province`, form.shippingAddress.province || '')
    data.append(`shippingAddress.district`, form.shippingAddress.district || '')
    data.append(`shippingAddress.address`, form.shippingAddress.formattedAddress || '')
    data.append(`shippingAddress.latitude`, form.shippingAddress.latitude || '')
    data.append(`shippingAddress.longitude`, form.shippingAddress.longitude || '')
    data.append(`shippingAddress.buildingNo`, form.shippingAddress.buildingNo || '')
    data.append(`shippingAddress.floorNo`, form.shippingAddress.floorNo || '')
    data.append(`shippingAddress.apartmentNo`, form.shippingAddress.apartmentNo || '')
    data.append(`shippingAddress.country`, form.shippingAddress.country || '')
    data.append(`shippingAddress.postalCode`, form.shippingAddress.postalCode || '')
    data.append(`workStartTime`, form.workStartTime || '')
    data.append(`workFinishTime`, form.workFinishTime || '')
    // data.append(`employeeId`, form.employeeInfo.id || '')
    data.append(`image`, form.image || '')
    data.append(`isAbroad`, form.isAbroad)
    data.append(`regionId`, form.region?.id || '')
    data.append(`shortName`, form.shortName || '')

    return postToFormDataToken(endPoints.customerEndPoints.ADD_CUSTOMER_URL, data)
}

// Çalışanın bilgisini getirir.
const getCustomerInfo = (customerId) => {
    return getToken(endPoints.customerEndPoints.GET_CUSTOMER_INFO_URL(customerId))
}

// Çalışan güncelleme
const updateCustomer = (customerId, form, transferParasut = false, yearCustomer = '', isActive = true) => {
    let data = new FormData();

    data.append(`fullName`, form.fullName)
    data.append(`companyName`, form.companyName)
    data.append(`phoneNo`, form.phoneNo.length === 10 ? `+90${form.phoneNo}` : form.phoneNo.length === 11 ? `+9${form.phoneNo}` : form.phoneNo)
    data.append(`taxNo`, form.taxNo)
    data.append(`taxOffice`, form.taxOffice)
    data.append(`email`, form.email || '')
    data.append(`faxNo`, form.faxNo || '')
    data.append(`address.province`, form.address.province || '')
    data.append(`address.district`, form.address.district || '')
    data.append(`address.address`, form.address.formattedAddress || '')
    data.append(`address.latitude`, form.address.latitude || '')
    data.append(`address.longitude`, form.address.longitude || '')
    data.append(`address.buildingNo`, form.address.buildingNo || '')
    data.append(`address.floorNo`, form.address.floorNo || '')
    data.append(`address.apartmentNo`, form.address.apartmentNo || '')
    data.append(`address.country`, form.address.country || '')
    data.append(`address.postalCode`, form.address.postalCode || '')
    data.append(`shippingAddress.province`, form.shippingAddress.province || '')
    data.append(`shippingAddress.district`, form.shippingAddress.district || '')
    data.append(`shippingAddress.address`, form.shippingAddress.formattedAddress || '')
    data.append(`shippingAddress.latitude`, form.shippingAddress.latitude || '')
    data.append(`shippingAddress.longitude`, form.shippingAddress.longitude || '')
    data.append(`shippingAddress.buildingNo`, form.shippingAddress.buildingNo || '')
    data.append(`shippingAddress.floorNo`, form.shippingAddress.floorNo || '')
    data.append(`shippingAddress.apartmentNo`, form.shippingAddress.apartmentNo || '')
    data.append(`shippingAddress.country`, form.shippingAddress.country || '')
    data.append(`shippingAddress.postalCode`, form.shippingAddress.postalCode || '')
    data.append(`workStartTime`, form.workStartTime || '')
    data.append(`workFinishTime`, form.workFinishTime || '')
    // data.append(`employeeId`, form.employeeInfo.id)
    data.append(`image`, form.image || '')
    data.append(`isAbroad`, form.isAbroad)
    data.append(`regionId`, form.regionId || '')
    data.append(`shortName`, form.shortName || '')
    data.append(`transferParasut`, transferParasut)
    data.append('yearTime', yearCustomer)
    data.append('isActive', isActive)
    if(form.parasutAccountId !== '')
    data.append('parasutAccountId', form.parasutAccountId);

    return putToFormDataToken(endPoints.customerEndPoints.UPDATE_CUSTOMER_URL(customerId), data)
}
// Müşterinin aktifliğini değiştirir.
const updateCustomerActive = (customerId, status) => {
    let data = new FormData()

    data.append('isActive', status)

    return putToFormDataToken(endPoints.customerEndPoints.UPDATE_CUSTOMER_ACTIVE_URL(customerId), data)
}

// Müşterinin üyelik bilgisini değiştirir.
const updateCustomerMember = (customerId, year) => {
    let data = new FormData()

    data.append('yearTime', year)

    return putToFormDataToken(endPoints.customerEndPoints.UPDATE_CUSTOMER_MEMBER_URL(customerId), data)
}

export const customerApi = {
    getEmployeeCustomers,
    getCustomers,
    addCustomer,
    getCustomerInfo,
    updateCustomer,
    updateCustomerActive,
    getCustomersList,
    updateCustomerMember,
    getRegionCustomers
}