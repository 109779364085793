const apiKey = 'activeUser';

// Tokenı localstorage kaydetme
const setToken = (token) => {
    localStorage.setItem('token', token)
}

// Tokenı localstoragedan alma
const getToken = () => {
    return localStorage.getItem('token')
}

// Giriş yapan kişiyi cryptolayarak localstoragea kaydetme
const setActiveUser = (value) => {
    localStorage.setItem(apiKey, value)
}

// Giriş yapan kişiyi decryptolayarak localstoragedan alma
const getActiveUser = () => {
    return localStorage.getItem(apiKey)
}

// Localstoragedan silme
const removeUser = () => {
    localStorage.removeItem('token');
    localStorage.removeItem(apiKey)
}

export const adminStorage = {
    setToken,
    setActiveUser,
    getToken,
    getActiveUser,
    removeUser
}