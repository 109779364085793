import {
  returnActiveParasutAccount,
  returnActiveMikrroAccount,
} from "@/helpers/ReturnActiveParasutAccount";

const BASE_BANK_ACCOUNTS = "BankAccount";

// let parasutAccountId = returnActiveParasutAccount()

// Tüm bölgeleri listeleme url
const GET_BANK_ACCOUNTS = `${BASE_BANK_ACCOUNTS}/BankAccountDetails?parasutAccountId=${returnActiveParasutAccount()}`;

// Tüm bölgeleri listeleme url
const GET_BANK_ACCOUNTS_OF_MIKRO = `${BASE_BANK_ACCOUNTS}/MikroBankAccountDetails?mikroAccountId=${returnActiveMikrroAccount()}`;

// Bölge ekleme url
const ADD_BANK_ACCOUNTS = `${BASE_BANK_ACCOUNTS}`;

// Bölge güncelleme url
const UPDATE_BANK_ACCOUNTS = (bankAccountId) =>
  `${BASE_BANK_ACCOUNTS}/${bankAccountId}`;

// Bölge bilgi getirme url
const GET_BANK_ACCOUNTS_INFO = (bankAccountId) =>
  `${BASE_BANK_ACCOUNTS}/${bankAccountId}`;

// Bölge silme url
const DELETE_BANK_ACCOUNTS = (bankAccountId) =>
  `${BASE_BANK_ACCOUNTS}/${bankAccountId}`;

// Bölgenin detaylarını çekme url (Bağlı olduğu çalışanlar)
const GET_BANK_ACCOUNTS_DETAIL_URL = (page = 1, limit = 10, search = "") =>
  `${BASE_BANK_ACCOUNTS}/Details?page=${page}&limit=${limit}&search=${search}&parasutAccountId=${returnActiveParasutAccount()}`;

export const bankAccountEndPoints = {
  GET_BANK_ACCOUNTS,
  GET_BANK_ACCOUNTS_OF_MIKRO,
  ADD_BANK_ACCOUNTS,
  UPDATE_BANK_ACCOUNTS,
  GET_BANK_ACCOUNTS_INFO,
  DELETE_BANK_ACCOUNTS,
  GET_BANK_ACCOUNTS_DETAIL_URL,
};
