import endPoints from "../EndPoint"
import { deleteToken } from "../requests/deleteToken"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"
import { putToken } from "../requests/putToken"

// Bölgelerin listelenmesi 
const getRegionsList = (page = 1, limit = 10, search = '', parasutAccountId = '') => {
    return getToken(endPoints.regionEndPoints.GET_REGIONS(page, limit, search, parasutAccountId))
}

// Bölge ekleme 
const addRegion = (form) => {
    let data = {
        'name': form.name
    }
    return postToken(endPoints.regionEndPoints.ADD_REGION, data)
}

// Bölge güncelleme 
const updateRegion = (form, regionId) => {
    let data = {
        'name': form.name,
        'isActive': form.isActive
    }
    return putToken(endPoints.regionEndPoints.UPDATE_REGION(regionId), data)
}

// Bölgeye ait bilgi getirme 
const getRegionInfo = (regionId) => {
    return getToken(endPoints.regionEndPoints.GET_REGION_INFO(regionId))
}

// Bölge silme
const deleteRegion = (regionId) => {
    return deleteToken(endPoints.regionEndPoints.DELETE_REGION(regionId))
}

// Bölgenin detaylarını çekme url (Bağlı olduğu çalışanlar)
const getRegionDetail = (page, limit, search) => {
    return getToken(endPoints.regionEndPoints.GET_REGION_DETAIL_URL(page, limit, search))
}

export const regionApi = {
    getRegionsList,
    addRegion,
    updateRegion,
    getRegionInfo,
    deleteRegion,
    getRegionDetail
}