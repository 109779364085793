import Vue from 'vue'
import Vuex from 'vuex'
import { admin } from './modules/Admin'
import { employee } from './modules/Employee'
import { parasutAccount } from './modules/ParasutAccount'
import { regions } from './modules/Regions'
import { bankAccounts } from './modules/BankAccounts'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        showMenu: true
    },
    getters: {
        getShowMenu: (state) => {
            return state.showMenu
        }
    },
    mutations: {
        SET_SHOW_MENU(state, payload) {
            state.showMenu = payload
        },
    },
    actions: {},
    modules: {
        regions,
        bankAccounts,
        admin,
        employee,
        parasutAccount
    }
})
