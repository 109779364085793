import { returnActiveParasutAccount } from "@/helpers/ReturnActiveParasutAccount";

const BASE_PRODUCT_CATEGORY = 'ProductCategory';

// let parasutAccountId = returnActiveParasutAccount()

// Şirkete göre ürün kategorilerini listeleme url
const GET_PRODUCT_CATEGORY_LIST_URL = ( page = 1, limit = 10, search = '') => `${BASE_PRODUCT_CATEGORY}?parasutAccountId=${returnActiveParasutAccount()}&page=${page}&limit=${limit}&search=${search}`

//Kategori detay getirme url
const GET_PRODUCT_CATEGORY_DETAIL_URL = (categoryId) => `${BASE_PRODUCT_CATEGORY}/${categoryId}`

//Kategori ekleme url
const ADD_PRODUCT_CATEGORY_URL = `${BASE_PRODUCT_CATEGORY}`

//Kategori güncelleme url
const UPDATE_PRODUCT_CATEGORY_URL = (categoryId) => `${BASE_PRODUCT_CATEGORY}/${categoryId}`

export const productCategoryEndPoints = {
    GET_PRODUCT_CATEGORY_LIST_URL,
    GET_PRODUCT_CATEGORY_DETAIL_URL,
    ADD_PRODUCT_CATEGORY_URL,
    UPDATE_PRODUCT_CATEGORY_URL
}
