const BASE_SPECIAL_DISCOUNT = 'SpecialDiscount';

// Tüm indirimleri listeleyen url
const GET_SPECIAL_DISCOUNT_URL = `${BASE_SPECIAL_DISCOUNT}`

// İndirim güncelleyen url
const UPDATE_SPECIAL_DISCOUNT_URL = (discountId) => `${BASE_SPECIAL_DISCOUNT}/${discountId}`

// İndirim ekleme url
const ADD_SPECIAL_DISCOUNT_URL = `${BASE_SPECIAL_DISCOUNT}`

// İndirim silme url
const DELETE_SPECIAL_DISCOUNT_URL = (discountId) => `${BASE_SPECIAL_DISCOUNT}/${discountId}`


export const specialDiscountEndPoints = {
    GET_SPECIAL_DISCOUNT_URL,
    UPDATE_SPECIAL_DISCOUNT_URL,
    ADD_SPECIAL_DISCOUNT_URL,
    DELETE_SPECIAL_DISCOUNT_URL
}
