import endPoints from "../EndPoint"
import { deleteToken } from "../requests/deleteToken"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"
import { putToken } from "../requests/putToken"

const getSpecialDiscountList = () => {
    return getToken(endPoints.specialDiscountEndPoints.GET_SPECIAL_DISCOUNT_URL)
}

const updateSpecialDiscount = (discountId, form) => {
    let data = form
    return putToken(endPoints.specialDiscountEndPoints.UPDATE_SPECIAL_DISCOUNT_URL(discountId), data)
}

const addSpecialDiscount = (form) => {
    let data = {
        'differentProductCount': form.differentProductCount,
        'discountRate': form.discountRate
    }
    return postToken(endPoints.specialDiscountEndPoints.ADD_SPECIAL_DISCOUNT_URL, data)
}

const deleteSpecialDiscount = (discountId) => {
    return deleteToken(endPoints.specialDiscountEndPoints.DELETE_SPECIAL_DISCOUNT_URL(discountId))
}

export const specialDiscountApi = {
    getSpecialDiscountList,
    updateSpecialDiscount,
    addSpecialDiscount,
    deleteSpecialDiscount
}