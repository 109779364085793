import { returnActiveParasutAccount } from "@/helpers/ReturnActiveParasutAccount";

const BASE_CUSTOMER = 'Customer';

// let parasutAccountId = returnActiveParasutAccount()

// Çalışana bağlı olan müşteriler listesi url
const GET_EMPLOYEE_CUSTOMERS_URL = (employeeRegionId = '', page = 1, limit = 10, searchKey = '', lastPlanFilter = 'All', maxLongitude = '', maxLatitude = '', minLongitude = '', minLatitude = '') => `${BASE_CUSTOMER}?regionId=${employeeRegionId}&page=${page}&limit=${limit}&searchKey=${searchKey}&lastPlanFilter=${lastPlanFilter}&maxLongitude=${maxLongitude}&minLongitude=${minLongitude}&maxLatitude=${maxLatitude}&minLatitude=${minLatitude}&parasutAccountId=${returnActiveParasutAccount()}`;

// Tüm çalışanları listeyen url
const GET_CUSTOMERS_URL = (page = 1, limit = 10, searchKey = '', lastPlanFilter = '', regionId = '', isYearCustomer = '', maxLongitude = '', maxLatitude = '', minLongitude = '', minLatitude = '') => `${BASE_CUSTOMER}?page=${page}&limit=${limit}&searchKey=${searchKey}&lastPlanFilter=${lastPlanFilter}&maxLongitude=${maxLongitude}&minLongitude=${minLongitude}&maxLatitude=${maxLatitude}&minLatitude=${minLatitude}&regionId=${regionId}&isYearCustomer=${isYearCustomer}&parasutAccountId=${returnActiveParasutAccount()}`
// Tüm çalışanları listeyen url
const GET_CUSTOMERS_LIST_URL = (page = 1, limit = 10, searchKey = '', lastPlanFilter = '', regionId = '', isYearCustomer = '', userType = "") => `${BASE_CUSTOMER}?page=${page}&limit=${limit}&searchKey=${searchKey}&lastPlanFilter=${lastPlanFilter}&userType=${userType}&regionId=${regionId}&isYearCustomer=${isYearCustomer}&parasutAccountId=${returnActiveParasutAccount()}`

// Tüm çalışanları bölgeye göre listeleyen url
const GET_REGION_CUSTOMERS_URL = (page = 1, limit = 10, regionId = '', searchKey = '') => `${BASE_CUSTOMER}?page=${page}&limit=${limit}&searchKey=${searchKey}&regionId=${regionId}&parasutAccountId=${returnActiveParasutAccount()}`

// Çalışanın bilgisini getiren url
const GET_CUSTOMER_INFO_URL = (customerId) => `${BASE_CUSTOMER}/${customerId}`

// Çalışan ekleme url
const ADD_CUSTOMER_URL = `${BASE_CUSTOMER}`

// Çalışan güncelleme url
const UPDATE_CUSTOMER_URL = (customerId) => `${BASE_CUSTOMER}/${customerId}`

// Çalışan aktiflik güncelleme url
const UPDATE_CUSTOMER_ACTIVE_URL = (customerId) => `${BASE_CUSTOMER}/${customerId}`

// Çalışan üyelik güncelleme url
const UPDATE_CUSTOMER_MEMBER_URL = (customerId) => `${BASE_CUSTOMER}/${customerId}`


export const customerEndPoints = {
    GET_EMPLOYEE_CUSTOMERS_URL,
    GET_CUSTOMERS_URL,
    GET_CUSTOMERS_LIST_URL,
    ADD_CUSTOMER_URL,
    GET_CUSTOMER_INFO_URL,
    UPDATE_CUSTOMER_URL,
    UPDATE_CUSTOMER_ACTIVE_URL,
    UPDATE_CUSTOMER_MEMBER_URL,
    GET_REGION_CUSTOMERS_URL
}
