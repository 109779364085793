import endPoints from "../EndPoint"
import { getToken } from "../requests/getToken"
import { putToken } from "../requests/putToken"

const getPaymentMethodDetail = (payType) => {
    return getToken(endPoints.paymentMethodEndPoints.GET_PAYMENT_SETTING_DETAIL_URL(payType))
}

const updatePaymentMethodActive = (paymentId, activeType) => {
    let data = {
        'isActive': activeType
    }
    return putToken(endPoints.paymentMethodEndPoints.UPDATE_PAYMENT_SETTING_ACTIVE_URL(paymentId), data)
}

export const paymentMethodApi = {
    getPaymentMethodDetail,
    updatePaymentMethodActive
}