import endPoints from "../EndPoint"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"
import { putToken } from "../requests/putToken"
import { returnActiveParasutAccount } from "@/helpers/ReturnActiveParasutAccount"

// Şirkete bağlı kategorileri getirir
const getProductCategoryList = (page, limit, search) => {
    return getToken(endPoints.productCategoryEndPoints.GET_PRODUCT_CATEGORY_LIST_URL(page, limit, search))
}

// Kategorinin detayını getirir.
const getProductCategoryDetail = (categoryId) => {
    return getToken(endPoints.productCategoryEndPoints.GET_PRODUCT_CATEGORY_DETAIL_URL(categoryId))
}

// Kategorinin detayını getirir.
const addProductCategory = (productCategoryName) => {
    let data = {
        "name": productCategoryName,
        "parasutAccountId": returnActiveParasutAccount()
    }
    return postToken(endPoints.productCategoryEndPoints.ADD_PRODUCT_CATEGORY_URL, data)
}

// Kategoriyi günceller.
const updateProductCategory = (catgeoryId, form) => {
    let data = form
    return putToken(endPoints.productCategoryEndPoints.UPDATE_PRODUCT_CATEGORY_URL(catgeoryId), data)
}

export const productCategoryApi = {
    getProductCategoryList,
    getProductCategoryDetail,
    addProductCategory,
    updateProductCategory
}