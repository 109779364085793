import localStorageManager from "@/services/localStorageManager";
import { decrypto } from "@/utils/Crypto";

export const returnActiveParasutAccount = () => {
  const localStorageAccount =
    localStorageManager.parasutAccountStorage.getParasutAccount();
  if (localStorageAccount) {
    const decyrptoAccount = decrypto(localStorageAccount);
    return decyrptoAccount.id;
  } else {
    return null;
  }
};
export const returnActiveMikrroAccount = () => {
  const localStorageAccount =
    localStorageManager.parasutAccountStorage.getMikroAccount();
  if (localStorageAccount) {
    const decyrptoAccount = decrypto(localStorageAccount);
    console.log(decyrptoAccount);
    return decyrptoAccount.mikroAccountId;
  } else {
    return null;
  }
};
