import endPoints from "../EndPoint"
import { deleteToken } from "../requests/deleteToken"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"

// Normal plan ekleme
const addPlanDefinition = (form) => {
    let data = {
        "startDate": new Date(form.startDate),
        "endDate": new Date(form.endDate),
        "expiredDay": form.planDays
    }
    return postToken(endPoints.planDefinitionEndPoints.ADD_PLAN_DEFINITION_URL, data)
}

// Normal planların listelenmesi
const getPlansDefinition = (page, limit) => {
    return getToken(endPoints.planDefinitionEndPoints.GET_PLANS_DEFINITION_URL(page, limit))
}

// Normal planları siler
const deletePlansDefinition = (planId) => {
    return deleteToken(endPoints.planDefinitionEndPoints.DELETE_PLANS_DEFINITION_URL(planId))
}

export const planDefinitionApi = {
    addPlanDefinition,
    getPlansDefinition,
    deletePlansDefinition
}