const apiKey = 'parasutAccount';

// Seçilen paraşüt hesabını localstorage kaydetme
const setParasutAccount = (token) => {
    localStorage.setItem(apiKey, token)
}

// Seçilen paraşüt hesabını localstoragedan alma
const getParasutAccount = () => {
    return localStorage.getItem(apiKey)
}


// Seçilen paraşüt hesabını localstoragedan alma
const getMikroAccount = () => {
    return localStorage.getItem("parasutAccount")
}


export const parasutAccountStorage = {
    setParasutAccount,
    getParasutAccount,
    getMikroAccount
}