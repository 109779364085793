const BASE_PARASUT_SYNC = 'ParasutSync';

// Paraşütteki çalışan listesini getiren url
const GET_PARASUT_USER_LIST = (parasutAccountId, page, limit) => `${BASE_PARASUT_SYNC}/Employees?parasutAccountId=${parasutAccountId}&page=${page}&limit=${limit}`

// Paraşütteki bölgeler listesini getiren url
const GET_PARASUT_REGION_LIST_URL = (parasutAccountId, page, limit) => `${BASE_PARASUT_SYNC}/Regions?parasutAccountId=${parasutAccountId}&page=${page}&limit=${limit}`

// Paraşütteki müşteriler listesini getiren url
const GET_PARASUT_CUSTOMER_LIST_URL = (parasutAccountId, page, limit, search = '') => `${BASE_PARASUT_SYNC}/Customers?parasutAccountId=${parasutAccountId}&page=${page}&limit=${limit}&search=${search}`

// Paraşütteki ürün kategorilerinin listesini getiren url
const GET_PARASUT_PRODUCT_CATEGORY_LIST_URL = (parasutAccountId, page, limit) => `${BASE_PARASUT_SYNC}/ProductCategories?parasutAccountId=${parasutAccountId}&page=${page}&limit=${limit}`

// Paraşütteki çalışanı sisteme ekleme url
const TRANSFER_ATTRIBUTES = (parasutAccountId, url) => `${BASE_PARASUT_SYNC}/${url}/${parasutAccountId}`

// Paraşütteki ürünlerin listesini getiren url
const GET_PARASUT_PRODUCT_LIST_URL = (parasutAccountId, page, limit, search = "") => `${BASE_PARASUT_SYNC}/Products?parasutAccountId=${parasutAccountId}&page=${page}&limit=${limit}&name=${search}`

export const parasutSyncEndPoints = {
    GET_PARASUT_USER_LIST,
    TRANSFER_ATTRIBUTES,
    GET_PARASUT_REGION_LIST_URL,
    GET_PARASUT_CUSTOMER_LIST_URL,
    GET_PARASUT_PRODUCT_CATEGORY_LIST_URL,
    GET_PARASUT_PRODUCT_LIST_URL
}
