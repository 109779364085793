import endPoints from "../EndPoint"
import { deleteToken } from "../requests/deleteToken"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"
import { putToken } from "../requests/putToken"

// Tekrarlayan planların listelenmesi
const getPlansInterval = (page, limit) => {
    return getToken(endPoints.planIntervalEndPoints.GET_PLANS_INTERVAL_URL(page, limit))
}

// Tekrarlayan planların listelenmesi
const addPlanInterval = (form) => {
    let data = {
        "startDayOfWeek": form.startDayOfWeek,
        "endDayOfWeek": form.endDayOfWeek,
        "startTimeSpan": form.startTimeSpan,
        "endTimeSpan": form.endTimeSpan,
        "planDays": form.planDays
    }
    return postToken(endPoints.planIntervalEndPoints.ADD_PLAN_INTERVAL_URL, data)
}

// Tekrarlayan planın statüsünün değiştirilmesi
const updatePlanInterval = (planId, value) => {
    let data = {
        'isActive': value
    }
    return putToken(endPoints.planIntervalEndPoints.UPDATE_PLAN_INTERVAL_URL(planId), data)
}

// Tekrarlayan planları siler.
const deletePlan = (planId) => {
    return deleteToken(endPoints.planIntervalEndPoints.DELETE_PLAN_URL(planId))
}

export const planIntervalApi = {
    getPlansInterval,
    addPlanInterval,
    updatePlanInterval,
    deletePlan
}