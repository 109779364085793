import endPoints from "../EndPoint"
import { getToken } from "../requests/getToken"
import { postToken } from "../requests/postToken"
import { putToken } from "../requests/putToken"

// Çalışanın planlarını 30 günlük listeler.
const getEmployeeMonthPlans = (employeeId, month, year) => {
    return getToken(endPoints.planEndPoints.GET_EMPLOYEE_MONTH_PLANS(employeeId, month, year))
}

// Çalışanın planlarının hepsini listeler.
const getEmployeePlans = (page, limit, employeeId, date, search) => {
    return getToken(endPoints.planEndPoints.GET_EMPLOYEE_PLANS_URL(page, limit, employeeId, date, search))
}

// Müşterinin geçmiş ve gelecek planlarını listeler.
const getCustomerPlans = (page, limit, customerId, timeType, visitType) => {
    return getToken(endPoints.planEndPoints.GET_CUSTOMER_PLANS(page, limit, customerId, timeType, visitType))
}

// Müşterinin geçmiş ve gelecek planlarını listeler.
const getPlanDetail = (planId) => {
    return getToken(endPoints.planEndPoints.GET_PLAN_DETAIL_URL(planId))
}

// Planın aktifliğini değiştirir.
const updatePlanActive = (planId, status) => {
    let data = {
        'isActive': JSON.parse(status)
    }
    return putToken(endPoints.planEndPoints.UPDATE_PLAN_ACTIVE_URL(planId), data)
}

// Planın ekleme.
const addPlan = (form) => {
    let data = {
        customerId: form.customer.id,
        employeeId: form.customer.employeeId,
        planDate: form.planDate
    }
    return postToken(endPoints.planEndPoints.ADD_PLAN_URL, data)
}

// çalışanın son güncelleme yaptığı planı getirir.
const lastEmployeePlan = (employeeId) => {
    return getToken(endPoints.planEndPoints.EMPLOYEE_LAST_PLAN_URL(employeeId))
}


export const planApi = {
    getEmployeeMonthPlans,
    getEmployeePlans,
    getCustomerPlans,
    getPlanDetail,
    updatePlanActive,
    addPlan,
    lastEmployeePlan
}