const BASE_EXTRA_PRODUCT = 'ExtraProduct';

// Kurala bağlı oran ürünleri veya ürün gruplarını listeleyen url
const GET_EXTRA_PRODUCT_LIST_URL = (extraProductRuleId = '', page = 1, limit = 10) => `${BASE_EXTRA_PRODUCT}?extraProductRuleId=${extraProductRuleId}&page=${page}&limit=${limit}`

// Kurala bağlı oran ürünleri veya ürün gruplarının tipini güncelleyen url
const UPDATE_EXTRA_PRODUCT_TYPE_URL = (productId) => `${BASE_EXTRA_PRODUCT}/${productId}`

// Kurala bağlı oran ürünleri veya ürün gruplarını silen url
const DELETE_EXTRA_PRODUCT_URL = (productId) => `${BASE_EXTRA_PRODUCT}/${productId}`

// Kurala bağlı oran ürün veya ürün grubu ekleyen url
const ADD_EXTRA_PRODUCT_URL = `${BASE_EXTRA_PRODUCT}`

export const extraProductEndPoints = {
    GET_EXTRA_PRODUCT_LIST_URL,
    UPDATE_EXTRA_PRODUCT_TYPE_URL,
    DELETE_EXTRA_PRODUCT_URL,
    ADD_EXTRA_PRODUCT_URL
}
