import endPoints from "../EndPoint"
import { getToken } from "../requests/getToken"
import { putToken } from "../requests/putToken"

// Ödeme ayarlarını listeleme
const getPaymentSettingList = () => {
    return getToken(endPoints.paymentSettingEndPoints.GET_PAYMENT_SETTING_URL)
}

// ödeme ayarlarını güncelleme
const updatePaymentSetting = (paymentId, form) => {
    let data = form
    return putToken(endPoints.paymentSettingEndPoints.UPDATE_PAYMENT_SETTING_URL(paymentId), data)
}

export const paymentSettingApi = {
    getPaymentSettingList,
    updatePaymentSetting
}