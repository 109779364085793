import { baseUrl } from '../BaseUrl';
import localStorageManager from '../localStorageManager';

/** token */
export const getPdfToken = (url) => {
    const token = localStorageManager.adminStorage.getToken();
    const axios = require('axios');

    let config = {
        method: 'get',
        url: `${baseUrl}/${url}`,
        responseType: "blob",
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    }
    return axios(config)
        .then((response) => {
            var file = new Blob([response.data], { type: 'application/pdf' });

            var reader = new FileReader();
            reader.readAsDataURL(file);
            return URL.createObjectURL(file)
        })
        .catch((error) => {
            // localStorageManager.userAuth.removeActiveUser();
            // window.location.href = '/user/giris-yap'
            return error;
        });
}