const BASE_PLAN = 'Plan';

// Çalışanın aylık olarak yaptığı planları lisleyen url
const GET_EMPLOYEE_MONTH_PLANS = (employeeId, month, year) => `${BASE_PLAN}/GetMonthlyPlansByEmployee?employeeId=${employeeId}&month=${month}&year=${year}`

// Çalışanın tüm planlarını listeleyen url
const GET_EMPLOYEE_PLANS_URL = (page = 1, limit = 10, employeeId = '', date = '', search = '') => `${BASE_PLAN}?page=${page}&limit=${limit}&employeeId=${employeeId}&date=${date}&search=${search}`

// Müşterinin geçmiş ve gelecek planlarını listeleyen url
const GET_CUSTOMER_PLANS = (page = 1, limit = 10, customerId = '', timeType = 'Future', visitType = '') => `${BASE_PLAN}/PlansByCustomer?page=${page}&limit=${limit}&customerId=${customerId}&timeType=${timeType}&planStatus=${visitType}`

// Plan detayını getiren url
const GET_PLAN_DETAIL_URL = (planId) => `${BASE_PLAN}/${planId}`

// Plan detayını getiren url
const UPDATE_PLAN_ACTIVE_URL = (planId) => `${BASE_PLAN}/${planId}`

// Plan detayını getiren url
const EMPLOYEE_LAST_PLAN_URL = (employeeId) => `${BASE_PLAN}/GetLastPlanWithEmployee?employeeId=${employeeId}`

// Plan ekleyen url
const ADD_PLAN_URL = `${BASE_PLAN}`

export const planEndPoints = {
    GET_EMPLOYEE_MONTH_PLANS,
    GET_EMPLOYEE_PLANS_URL,
    GET_CUSTOMER_PLANS,
    GET_PLAN_DETAIL_URL,
    UPDATE_PLAN_ACTIVE_URL,
    ADD_PLAN_URL,
    EMPLOYEE_LAST_PLAN_URL
}
