import { returnActiveParasutAccount } from "@/helpers/ReturnActiveParasutAccount";

const BASE_TRANSACTIONS = "Transaction";

// let parasutAccountId = returnActiveParasutAccount()

// Tüm bölgeleri listeleme url
const GET_TRANSACTIONS = (query) => `${BASE_TRANSACTIONS}${query}`;

// Bölge ekleme url
const ADD_TRANSACTIONS = `${BASE_TRANSACTIONS}`;

// Bölge ekleme url
const ADD_TRANSACTIONS_TO_MIKRO = `${BASE_TRANSACTIONS}/AddTransactionToMikro`;

// Bölge güncelleme url
const UPDATE_TRANSACTIONS = (id) => `${BASE_TRANSACTIONS}/${id}`;

// Bölge bilgi getirme url
const GET_TRANSACTIONS_INFO = (id) => `${BASE_TRANSACTIONS}/${id}`;

// Bölge bilgi getirme url
const GET_USER_PAID_TRANSACTION_BALANCE = (query) =>
  `${BASE_TRANSACTIONS}/GetUserPaidTransactionBalance${query}`;

// Bölge silme url
const DELETE_TRANSACTIONS = () => `${BASE_TRANSACTIONS}/DeleteTransaction`;

// Bölgenin detaylarını çekme url (Bağlı olduğu çalışanlar)
const GET_TRANSACTIONS_DETAIL_URL = (page = 1, limit = 10, search = "") =>
  `${BASE_TRANSACTIONS}/Details?page=${page}&limit=${limit}&search=${search}&parasutAccountId=${returnActiveParasutAccount()}`;

export const transactionsEndPoints = {
  GET_TRANSACTIONS,
  ADD_TRANSACTIONS,
  ADD_TRANSACTIONS_TO_MIKRO,
  GET_USER_PAID_TRANSACTION_BALANCE,
  UPDATE_TRANSACTIONS,
  GET_TRANSACTIONS_INFO,
  DELETE_TRANSACTIONS,
  GET_TRANSACTIONS_DETAIL_URL,
};
