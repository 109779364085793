import CryptoJS from 'crypto-js';

export const crypto = (value) => {
    let cryptoValue = CryptoJS.AES.encrypt(JSON.stringify(value), 'secret key 123').toString();

    return cryptoValue;
}

export const decrypto = (value) => {
    let bytes = CryptoJS.AES.decrypt(value, 'secret key 123');
    let decyrptoValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decyrptoValue
}