const BASE_SALE_NOTE = 'SaleNote';

// Satışa ait notları listeleme
const GET_SALE_NOTES_URL = (saleId, page = 1, limit = 5) => `${BASE_SALE_NOTE}?saleId=${saleId}&page=${page}&limit=${limit}`

// Satışa not ekleme
const ADD_SALE_NOTE_URL = `${BASE_SALE_NOTE}`

// Satışa atılan notu favoriye ekleme
const UPDATE_FAVORITE_SALE_NOTE_URL = (saleNoteId) => `${BASE_SALE_NOTE}/${saleNoteId}`

// Satışa atılan notu güncelleme
const UPDATE_SALE_NOTE_URL = (saleNoteId) => `${BASE_SALE_NOTE}/${saleNoteId}`

// Satışa atılan notu silme
const DELETE_SALE_NOTE_URL = (saleNoteId) => `${BASE_SALE_NOTE}/${saleNoteId}`

export const saleNoteEndPoints = {
    GET_SALE_NOTES_URL,
    ADD_SALE_NOTE_URL,
    UPDATE_FAVORITE_SALE_NOTE_URL,
    UPDATE_SALE_NOTE_URL,
    DELETE_SALE_NOTE_URL
}